<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card class="elevation-12">
          <v-toolbar color="primary" dark>
            <v-toolbar-title>Logowanie</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent="login">
              <v-text-field
                v-model="username"
                label="Nazwa użytkownika"
                prepend-icon="mdi-account"
                type="text"
                required
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Hasło"
                prepend-icon="mdi-lock"
                type="password"
                required
              ></v-text-field>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit">Zaloguj</v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar.show">
      {{ snackbar.text }}
      <v-btn color="red" text @click="snackbar.show = false">Zamknij</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import Cookies from 'js-cookie'

export default {
  data() {
    return {
      username: '',
      password: '',
      snackbar: {
        show: false,
        text: ''
      },
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch('login', {
          username: this.username,
          password: this.password,
        });

        const accessToken = this.$store.state.token;
        if (accessToken) {
          Cookies.set('accessToken', accessToken, { expires: 7 });
        }

        this.$router.push('/panel');
      } catch (error) {
        console.error('Błąd logowania:', error);
        this.snackbar.text = 'Błąd logowania: ' + error.message;
        this.snackbar.show = true;
      }
    },
  },
};
</script>

