// store.js
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null, // Bearer token
    user: null, // Zalogowany użytkownik
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    async login({ commit }, { username, password }) {
      try {
        // Wywołaj API logowania
        const response = await fetch('/api/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username, password }),
        });

        if (response.ok) {
          const data = await response.json();
          const token = data.token; // Przyjmuję, że odpowiedź zawiera token
          commit('setToken', token);
          // Opcjonalnie można pobrać dane użytkownika i zapisać je w stanie.
          // commit('setUser', data.user);
        } else {
          throw new Error('Nieudane logowanie');
        }
      } catch (error) {
        console.error('Błąd logowania:', error);
        throw error;
      }
    },
  },
});
