<template>
  <v-container>
    <v-form @submit="submitForm">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field v-model="fullName" label="Imię i nazwisko" required></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="selectedMonth" :items="months" label="Miesiąc" required :item-text="item => getMonthName(item)"></v-select>
        </v-col>
      </v-row>
      <v-row v-for="(work, index) in works" :key="index">
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-btn icon @click="toggleWork(index)">
                <v-icon>{{ work.expanded ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
              </v-btn>
              <span class="headline">Praca {{ index + 1 }}</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="removeWork(index)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-title>
            <v-expand-transition>
              <v-card-text v-show="work.expanded">
                <v-text-field v-model="work.companyName" label="Nazwa firmy" required></v-text-field>
                <v-text-field v-model="work.startTime" label="Godzina rozpoczęcia" required :rules="startTimeRules" @input="formatTime(work, 'startTime')"></v-text-field>
                <v-text-field v-model="work.endTime" label="Godzina zakończenia" required :rules="endTimeRules" @input="formatTime(work, 'endTime')"></v-text-field>
                <v-text-field v-model="work.firstDay" label="Pierwszy dzień" required type="number"></v-text-field>
                <v-text-field v-model="work.lastDay" label="Ostatni dzień" required type="number"></v-text-field>
                <div v-if="!isValidDates(work)">
                  <p>Błąd: Dni nie mogą na siebie nachodzić.</p>
                </div>
              </v-card-text>
            </v-expand-transition>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn @click="addWork">Dodaj pracę</v-btn>
          <v-btn type="submit">Wyślij</v-btn>
          <v-btn @click="showHoursPreview = true">Podgląd godzin</v-btn>
        </v-col>
      </v-row>

      <v-dialog v-model="showHoursPreview" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Podgląd godzin
          <v-spacer></v-spacer>
          <v-btn icon @click="showHoursPreview = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="(work, index) in works" :key="'preview-' + index">
              <v-list-item-content>
                <v-list-item-title>Praca {{ index + 1 }}: {{ work.companyName }}</v-list-item-title>
                <v-list-item-subtitle>Godziny: od {{ work.startTime }} do {{ work.endTime }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn text @click="showHoursPreview = false">Zamknij</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-form>
  </v-container>
</template>

<script>
import Cookies from 'js-cookie';
export default {
  data() {
    return {
      fullName: '',
      selectedMonth: '',
      showHoursPreview: false,
      months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
      works: [
        { companyName: '', startTime: '', endTime: '', firstDay: 0, lastDay: 0, expanded: true }
      ],
      startTimeRules: [
        (v) => !!v || 'Godzina rozpoczęcia jest wymagana',
        (v) => /^([01]?[0-9]|2[0-4])(:[0-5][0-9])?$/.test(v) || 'Nieprawidłowy format godziny'
      ],
      endTimeRules: [
        (v) => !!v || 'Godzina zakończenia jest wymagana',
        (v) => /^([01]?[0-9]|2[0-4])(:[0-5][0-9])?$/.test(v) || 'Nieprawidłowy format godziny'
      ]
    };
  },
  methods: {
    addWork() {
      this.works.push({ companyName: '', startTime: '', endTime: '', firstDay: 0, lastDay: 0, expanded: true });
    },
    removeWork(index) {
      this.works.splice(index, 1);
    },
    
    async submitForm(event) {
    event.preventDefault();

    // Odczytaj token dostępu z plików cookies
    const accessToken = Cookies.get('accessToken');

    if (!accessToken) {
      console.error('Brak dostępu. Użytkownik nie jest zalogowany.');
      return;
    }

    // Prepare the data to be sent in the request body
    const requestData = {
      works: this.works,
      name: this.fullName,
      month: this.getTranslatedMonth(this.selectedMonth),
    };

    // Send data to the controller with the access token in the Authorization header
    fetch("/api/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
        "Authorization": `Bearer ${accessToken}`, // Dodaj token dostępu do nagłówka autoryzacji
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `RK ${this.fullName} ${this.getTranslatedMonth(this.selectedMonth)} 2024.xlsx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("Błąd podczas pobierania pliku Excel:", error);
      });
  },
    isValidDates(work) {
      return work.lastDay >= work.firstDay;
    },
    formatTime(work, field) {
      let value = work[field];
      value = value.replace(/[^0-9:]/g, ''); // Usuń wszystkie znaki oprócz cyfr i dwukropka
      value = value.slice(0, 5); // Ogranicz do maksymalnie 5 znaków

      if (value.length === 2 && !value.includes(':')) {
        value += ':';
      }

      work[field] = value;
    },
    toggleWork(index) {
      this.works[index].expanded = !this.works[index].expanded;
    },
    getMonthName(month) {
      return this.getTranslatedMonth(month);
    },
    getTranslatedMonth(month) {
      const translations = {
        'Styczeń': 'Januar',
        'Luty': 'Februar',
        'Marzec': 'März',
        'Kwiecień': 'April',
        'Maj': 'Mai',
        'Czerwiec': 'Juni',
        'Lipiec': 'Juli',
        'Sierpień': 'August',
        'Wrzesień': 'September',
        'Październik': 'Oktober',
        'Listopad': 'November',
        'Grudzień': 'Dezember'
      };

      return translations[month] || '';
    }
  },
};
</script>

<style>
.container {
  margin: auto;
}
.headline {
  margin-left: 8px;
}
</style>