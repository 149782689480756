<template>
  <v-app>
    <v-app-bar
      app
      color="white"
    >
      <div class="d-flex align-center">
        <v-title class="title ml-3">Reisekosten</v-title>
      </div>
      <v-spacer></v-spacer>

      <v-div
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">lohn@integrale-climasysteme.de</span>
        <v-icon>mdi-email</v-icon>
      </v-div>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
