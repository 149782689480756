import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/ReisaFormView.vue'
import LoginView from '../views/LoginView.vue'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const requireAuth = (to, from, next) => {
  const isAuthenticated = Cookies.get('accessToken') !== null;
  if (isAuthenticated) {
    next();
  } else {
    next('/login'); // Przekieruj na stronę logowania
  }
};

const routes = [
  {
    path: '/panel',
    name: 'ReisaForm',
    component: HomeView,
    beforeEnter: requireAuth,
  },
  {
    path: '/', // Ścieżka dla strony logowania
    name: 'login',
    component: LoginView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router